import React, {Component} from 'react'

class Search extends Component {
  render() {
    return (
      <>

      </>
    )
  }
}

export default Search
